@media (min-width: 1024px) {
    #hero {
      background-attachment: fixed;
    } 
  }

  @media (max-width: 992px) {
    #hero .container {
      padding-top: 0;
    }
    #testimonials .slick-dots {
      bottom: -40px;
    }
  
}


@media (max-width: 768px) {
    #header {
        padding-top: 15px;
        padding-bottom: 15px;
    }
    #intro {
        padding-top: 42px;
        padding-bottom: 50px;
    }
    #intro h1 {
        font-size: 26px;
    }

    #hero{
      height: 60vh;
    }
    
    #hero h1{
      font-size: 14px;
    }

    #hero h2{
      font-size: 30px;
    }

    #hero .breadcrumb .breadcrumb-item{
        font-size: 14px;
    } 

    #hero .btn{
      font-size: 12px;
    }

    .css-1rwt2y5-MuiButtonBase-root-MuiButton-root, .css-79xub{
      margin-left: 10px !important;
      font-size: 12px !important;
    }

    .copyright p, .copyright a {
        font-size: 14px;
    }

    .contact-us .contact-us-content {
      border-radius: 25px;
      padding: 30px;
      background-color: #3366FF;
      position: relative;
      z-index: 1;
    }

    .accordion {
      border-radius: 25px;
      padding: 50px 20px 30px 20px;
    }

    .service {
      margin-top: 20px;
    }

    .service .btn {
        margin-top: -20px;
    }

    .sub-heading {  
      font-size: 12px;
  }

  .custom-icon{
    width: 60%;
  }

  .custom-text{
    font-size: 14px;
  }

  #testimonials .slick-dots {
    bottom: -50px;
}

#testimonials:before,
#testimonials:after {
    display: none;
}

.testimonial-item p {
    font-size: 16px;
    line-height: 1.5;
}

  #services-details-top p {
    text-align: center;
  }

  .colm-1{
    text-align: left !important;
  }

  .social-icons{
    margin: 10px !important;
  }
  
  .icon-container{
    margin:20px 0;
  }

  .error-image{
    width: 80%;
  }
}

@media (max-width: 576px) {
  .testimonial-item {
      min-height: 250px;
      margin-top: 30px;
  }
}