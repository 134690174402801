/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital@0;1&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital@0;1&display=swap');

/* Color Variables */
:root {
    --color-primary: #3366FF;
    --color-secondary: #264070;
    --color-bg: #EDF3FF;
    --font-primary: 'Rubik', system-ui, -apple-system, 'Roboto', sans-serif;
}

/* Body */
body {
    font-family: var(--font-primary);
}

/* Links */
a {
    color: var(--color-primary);
    text-decoration: none;
}

.breadcrumbs {
  padding-top: 35px;
  padding-bottom: 35px;
  background-color: #295bf0e3;
}

.breadcrumbs h1, .breadcrumbs p {
  font-family: 'Playfair';
  color: #fff;
  margin: unset;
}

/* Headings */
h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    color: #092032;
}

/* Sections */
section {
    padding-top: 60px;
    padding-bottom: 60px;
}

/* Header */
#header .container {
    display: flex!important;
    align-items: center!important;
    justify-content: space-between!important;
}

.MuiListItemIcon-root {
    min-width: 42px !important;
}

/* Navbar */
.navbar ul {
    margin-top: 1rem;
    display: flex;
    list-style: none;
    align-items: center;
    padding-left: 0;
}

.navbar ul li {
    padding: 10px 0 10px 40px;
}

.navbar ul li:first-child {
    padding-left: 0;
}

.navbar ul li a {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
}

.navbar ul li a:hover {
    color: var(--color-primary);
}

.sub-heading {
    margin-bottom: 14px;
    height: 35px;
    color: #20b0ed;
    text-transform: uppercase;
    opacity: 1;
    letter-spacing: 1.4px;  
    font: normal normal 700 14px/70px var(--font-primary);
}

.sub-heading:before {
    display: inline-block;
    content: "";
    border-top: 2px solid #20b0ed;
    width: 22px;
    margin: -15px 10px -13px 0;
    transform: translateY(-1rem);
}

/* Keyframes */
@keyframes textclip {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

/* Heading Animation */
.heading-animate {
    font: normal normal 700 var(--font-primary);
    letter-spacing: 0;
    color: #0e52ad;
    background-image: linear-gradient(-225deg,#0a90d8,#076cb5 29%,#0a90d8 67%,#023778 80%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 4s linear infinite;
}

/* Hero Section */
#hero {
    width: 100%;
    height: 80vh;
    background: url("../images/hero-bg.jpg") top center;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Playfair Display', serif;
}

#hero .container {
    position: relative;
}

#hero h1 {
    font-size: 25px;
    letter-spacing: 2px;
}

#hero .breadcrumb .breadcrumb-item {
    font-size: 20px;
}

.hero-text .breadcrumb-item+.breadcrumb-item::before {
    content: "-";
    color: white;
}

#hero:before {
    content: "";
    background: rgba(0, 0, 0, .75);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

#hero .btn {
    background-color: var(--color-primary);
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    color: white;
    padding: 10px 20px;
    border-radius: 30px;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
}

#hero .btn:hover {
    background-color: var(--color-secondary);
}

/* Service Section */
.service {
    padding: 32px;
    background-color: #fff;
    border: 1px dotted var(--color-primary);
    box-shadow: var(--shadow);
}

.service h5 {
    margin-top: 24px;
    margin-bottom: 14px;
}

.service img {
    width: 90px;
}

.service .btn {
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
}

.service .btn:hover {
    color: #fff;
    background-color: var(--color-primary);
}

.service {
    padding: 32px;
    background-color: #fff;
    border: 1px dotted var(--color-primary);
    box-shadow: var(--shadow);
    position: relative;
    margin-top: 20px;
}

.service .btn {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    margin-top: -20px; /* Adjust the margin here */
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
}

.service .btn:hover {
    color: #fff;
    background-color: var(--color-primary);
}


/* Footer Section */
#footer {
    background-color: #312D46;
    padding-bottom: 1px;
}

#footer .colm-1 p {
    font-size: 16px;
    line-height: 24px;
    margin-top: 25px;
}

#footer h5 {
    color: #ffffff;
    position: relative;
    margin-bottom: 25px;
}

#footer a, #footer p, #footer .contact svg {
    color: #ffffff;
    opacity: .7;
}

#footer a:hover {
    opacity: 1;
}

#footer hr {
    color: #ffffff;
    opacity: .5;
}

#footer .contact svg {
    float: left;
    font-size: 22px;
}

#footer .contact p {
    font-size: 16px;
    line-height: 24px;
    padding-left: 30px;
}

#footer .contact a {
    font-size: 16px;
    line-height: 24px;
    padding-left: 10px;
}

#footer h5:before, #profile h2::before {
    content: "";
    position: absolute;
    height: 2px;
    width: 40px;
    background-color: var(--color-primary);
    top: 34px;
}

.copyright {
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.copyright p, .copyright a {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: #FCFCFC;
    opacity: 0.8;
}

.copyright a:hover {
    color: var(--color-primary);
}

/* Contact Style */
.contact-us {
    margin-top: 50px;
    position: relative;
}

.contact-us:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 65%;
    height: 100%;
    background-color: #f1f0fe;
    content: '';
    border-top-right-radius: 500px;
    border-bottom-right-radius: 500px;
}

.contact-us .contact-us-content {
    border-radius: 25px;
    padding: 80px;
    background-color: var(--color-primary);
    position: relative;
    z-index: 1;
}

.contact-us .contact-us-content::before {
    background: url(../images/contact-dec-01.png);
    position: absolute;
    left: 50%;
    transform: translateX(-149px);
    opacity: 0.5;
    top: 0;
    width: 318px;
    height: 119px;
    content: '';
    z-index: 2;
}

.contact-us .contact-us-content::after {
    background: url(../images/contact-dec-02.png);
    position: absolute;
    right: 0;
    bottom: 0;
    width: 183px;
    height: 149px;
    content: '';
    z-index: 2;
}

#contact-form input {
    width: 100%;
    height: 50px;
    border-radius: 25px;
    background-color: rgba(249, 235, 255, 0.20);
    border: none;
    outline: none;
    font-weight: 300;
    padding: 0px 20px;
    font-size: 14px;
    color: #fff;
    margin-bottom: 30px;
    position: relative;
    z-index: 3;
}

#contact-form input::placeholder {
    color: #fff;
}

#contact-form textarea {
    width: 100%;
    height: 120px;
    border-radius: 25px;
    background-color: rgba(249, 235, 255, 0.20);
    border: none;
    outline: none;
    font-weight: 300;
    padding: 20px;
    font-size: 14px;
    color: #fff;
    margin-bottom: 30px;
    position: relative;
    z-index: 3;
}

#contact-form textarea::placeholder {
    color: #fff;
}

#contact-form button {
    border: none;
    height: 50px;
    font-size: 14px;
    font-weight: 600;
    background-color: #fff;
    padding: 0px 25px;
    border-radius: 25px;
    color: var(--color-primary);
    transition: all .4s;
    position: relative;
    z-index: 3;
}

#contact-form button:hover {
    opacity: 0.8;
}

.contact-us-content .more-info {
    text-align: center;
    background: rgb(85,0,227);
    background: linear-gradient(90deg, rgba(85,0,227,1) 0%, rgba(198,61,255,1) 100%);
    border-radius: 0px 0px 23px 23px;
    padding: 45px 30px 15px 30px;
}

.contact-us-content .more-info .info-item {
    text-align: center;
    margin-bottom: 30px;
}

.contact-us-content .more-info i {
    font-size: 32px;
    color: #fff;
    margin-bottom: 15px;
}

.contact-us-content .more-info h4 a {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
}

/* Accordion */
.accordion {
    background-color: var(--color-primary);
    border-radius: 25px;
    padding: 80px 50px 50px 50px;
    overflow: hidden;
    transition: padding 0.5s ease-in-out;
}

.accordion-item {
    background-color: #fff;
    border-radius: 25px !important;
    margin-bottom: 30px;
    border: none;
    transition: margin-bottom 2s ease-in-out;
}

.accordion-item .accordion-button {
    outline: none;
    box-shadow: none;
    border-radius: 40px !important;
}

.accordion-button:not(.collapsed) {
    color: var(--color-primary);
    background-color: #fff;
}

h2.accordion-header button {
    padding: 15px 25px;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 600;
}

.accordion-button::after {
    font-size: 18px;
    font-weight: 500;
    background-image: none !important;
    content: '+';
    width: 30px;
    height: 30px;
    display: inline-block;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    background-color: var(--color-primary);
    color: #fff;
}

.accordion-button.active::after {
    background-image: none;
    line-height: 32px;
    content: '-';
}

.accordion-body {
    padding: 0px 50px 30px 25px;
    font-size: 14px;
    line-height: 20px;
    color: #4a4a4a;
}

/* Custom Text */
.custom-text {
    font: normal normal normal 14px/32px;
    letter-spacing: .25px;
    color: #374957;
    opacity: .8;
    text-align: center;
}

/* Custom Icon */
.custom-icon {
    width: 100px;
    display: block;
    margin: 0 auto;
}

/* Top Button */
.top-to-btn {
    position: relative;
}

/* Icon Position */
.icon-position {
    position: fixed;
    bottom: 40px;
    right: 25px;
    z-index: 20;
}

/* Icon Style */
.icon-style {
    background-color: var(--color-primary);
    border-radius: 50%;
    height: 35px;
    width: 35px;
    color: #fff;
    cursor: pointer;
    transition: all .5s ease-in-out;
}

.icon-style:hover {
    animation: none;
    background: var(--color-secondary);
}

/* Domain Container, Testimonial Container */
.domain-container, .testimonial-container {
    width: 90%; /* Adjust the width as needed */
    margin: 0 auto; /* Center the container */
}

/* Testimonials Section */
#testimonials .slick-dots {
    bottom: -30px;
}

#testimonials {
    position: relative;
    padding: 60px 0;
}

#testimonials:before,
#testimonials:after {
    position: absolute;
    content: '';
    z-index: 1;
    opacity: 0.7;
}

#testimonials:before {
    background-image: url(../images/testimonials-left-dec.png);
    left: 0;
    top: 0;
    width: 593px;
    height: 352px;
}

#testimonials:after {
  background-image: url(../images/testimonials-right-dec.png);
  right: 0;
  bottom: 0;
  width: 632px;
  height: 352px;
}

.testimonial-item {
  text-align: center;
  min-height: 200px;
  margin-top: 50px;
  padding: 0 20px;
}

.testimonial-item p {
  font-family: 'PT Serif', serif;
  font-style: italic;
  font-size: 19px;
  line-height: 1.6;
  margin-bottom: 30px;
}

.testimonial-item h4 {
    font-size: 19px;
    font-weight: 700;
    color: #1e1e1e;
    margin-bottom: 5px;
}

.testimonial-item span {
    font-size: 15px;
    color: var(--color-primary);
    font-weight: 700;
}

.css-1rwt2y5-MuiButtonBase-root-MuiButton-root, .css-79xub{
  margin-left: 20px !important;
  background-color: var(--color-primary) !important;
  color: white !important;
  padding: 10px 20px !important;
  border: none !important;
  border-radius: 30px !important;
  text-transform: none !important;
}

.css-1rwt2y5-MuiButtonBase-root-MuiButton-root:hover, .css-79xub:hover{
  background-color: var(--color-secondary) !important;
}

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root, .css-ohyacs{
  padding-bottom: 2px !important;
}

.css-qfso29-MuiTypography-root-MuiDialogContentText-root, .css-o3d33y{
  margin-bottom: 15px !important;
  font-size: 14.5px !important;
}

.service-list li{
  font-size: 20px;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 10px;
  opacity: .7;
}

#services-details-top{
  padding-bottom: unset;
}

#services-details-top p, #services-details p {
  opacity: .7;
  font-style: italic;
}

#services-details {
  padding-top: unset;
}

#services-details-top .patent-service-img{
  width: 400px;
}

.error-image{
    width: 40%;
}